<template>
  <div class="">
    <!--Filters-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <b-row no-gutters class="w-100">
          <b-col>
            <h5>{{ msg("Filters") }}</h5>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-link style="color: inherit" @click="refresh()">
                <feather-icon icon="RefreshCcwIcon" size="16"/>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ msg("ID") }}</label>
            <b-form-input
                v-model="filter.like.id"
                class="d-inline-block mr-1"
            />
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ msg("Provider") }}</label>
            <b-form-input
                v-model="filter.like.provider"
                class="d-inline-block mr-1"
            />
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ msg("Brand") }}</label>
            <b-form-input
                v-model="filter.like.brand"
                class="d-inline-block mr-1"
            />
          </b-col>

          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ msg('Status') }}</label>
            <v-select
                v-model="filter.in.status"
                :options="gasStatus"
                class="w-100"
                label="text"
                :reduce="(status) => status.value"
                multiple
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <div class="card-header" style="overflow: hidden">
        <i class="fas fa-fire" style="margin-right: 2px"></i>

        <span class="card-title m-0">{{ msg('Gas provider') }}</span>
        <b-col>
          <div class="d-flex justify-content-end">
            <b-link style="color: inherit" @click="refresh()">
              <feather-icon icon="RefreshCcwIcon" size="16"/>
            </b-link>
          </div>
        </b-col>
      </div>
      <!--Card Content-->
      <b-card-body>
        <div class="">
          <!-- Table Top -->
          <b-row no-gutters class="mb-1">
            <!-- Per Page -->
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>{{ msg('Show') }}</label>
              <v-select
                  v-model="page.amount"
                  :options="[10, 25, 50, 100]"
                  :clearable="false"
                  @option:selected="refresh"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>{{ msg('entries') }}</label>
            </b-col>
            <!-- Search -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <span class="mr-1"></span>
                <b-button variant="primary" @click="onNewGas(0)">
                  <i class="fas fa-fire" style="margin-right: 2px"></i>

                  <span class="text-nowrap">{{ msg('New provider') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>
        <!--Table-->
        <b-table
            ref="gas-table"
            striped
            hover
            responsive
            v-bind:items="gass"
            v-bind:fields="fields"
            :busy="loading"
            @sort-changed="onSort"
            @row-clicked="onRowClick"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mx-1"></b-spinner>
              <strong>{{ msg('Loading') }}...</strong>
            </div>
          </template>


          <template #cell(registered)="data">
            {{ $moment(data.value, 'YYYY-MM-DD').format("DD.MM.YYYY") }}
          </template>


          <template #cell(propertyIds)="data">
            <b-link
                v-if="data.value && data.value.length > 0"
                @click="showRealEstateModal"
                variant="danger"
                style="font-weight: bold; cursor: pointer"
            >
              <feather-icon icon="HomeIcon" size="16" style="margin-right: 5px;"/>
              {{ msg('View Real Estates') }}
            </b-link>

            <!-- Real Estate Modal -->
            <b-modal
                id="realEstateModal"
                v-model="showModal"
                title="Real Estate List"
                size="lg"
                hide-footer
            >
              <b-list-group>
                <b-list-group-item
                    v-for="(realEstate, index) in realEstates"
                    :key="index"
                    style="cursor: pointer"
                    @click="routeToProperty(realEstate.id)"
                >
                  <b-icon icon="building" class="mr-2"/>
                  {{ realEstate.name }}
                </b-list-group-item>
              </b-list-group>
            </b-modal>
          </template>

          <template #cell(status)="data" class="p-0">
            <b-badge :variant="statusVariants[data.value]">{{ msg(data.value) }}</b-badge>
          </template>
          <template #cell(imageUri)="data">
            <div>
              <b-img :src="data.value" fluid thumbnail style="max-width: 50px; max-height: 50px"/>
            </div>
          </template>
        </b-table>

        <!--Table Paginator-->
        <div class="text-center w-100">
          <div class="d-inline-block">
            <b-pagination
                v-model="page.currentPage"
                :total-rows="page.total"
                :per-page="page.amount"
                first-number
                last-number
                @input="refresh"
            />
          </div>
          <div class="d-inline-block px-2"><strong>{{ msg('Total') }}</strong> {{ page.total }}</div>
        </div>
      </b-card-body>
    </b-card>
    <b-modal no-fade v-model="dialogs.newGas" size="lg" ref="gas-modal" id="gas-modal"
             :hide-footer="true" no-close-on-backdrop centered>
      <gas-panel ref="gasPanel" @submitted="onSubmitted"/>
    </b-modal>
    <b-modal no-fade v-model="dialogs.updateGas" size="lg" ref="gas-mofddal" id="roudfter-modal"
             :hide-footer="true" no-close-on-backdrop centered>
      <edit-gas-panel ref="routefdrPanel" @submitted="onSubmitted" :id="selected"/>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import GasPanel from "./gasPanel.vue";
import EditGasPanel from "@/views/realEstate/gas/editGasPanel.vue";

export default {
  name: 'Gass',
  components: {GasPanel, EditGasPanel},
  data() {
    return {
      loading: true,

      amount: 10,
      showModal: false,
      gass: [],
      fields: [],
      selected: null,
      realEstates: {},

      filter: {
        like: {search: ''},
        eq: {},
        lte: {},
        gte: {},
        in: {status: []}
      },
      sort: {
        by: 'creationDate',
        desc: true,
        direction: 'desc'
      },
      statusVariants: {

        "active": "success",
        "inactive": "secondary",
        "in progress": "warning",
        "disturbance": "danger"
      },


      page: {
        amount: 10,
        currentPage: 1,
        total: 0
      },
      dialogs: {newGas: false, updateGas: false},
      //Listeners
      listeners: {
        refresh: () => {
        }
      }

    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.refresh()
      }
    },
    file: {
      handler() {
      }
    }
  },
  computed: {
    ...mapGetters('data', ['getOrganizationWebSite', 'getRealEstatesWebSite']),
    ...mapGetters('data', ['getUserFullName', 'getUserAcronyms']),
    ...mapGetters('data', ['getAllUsers', 'getUsersInTeam', 'getUserRole', 'getRoles', 'getRoleName']),
    ...mapGetters('translator', ['msg']),
    ...mapGetters('gas', ['allGasStatus']),

    gasStatus() {
      let $this = this;
      return this.allGasStatus.map(status => ({
        value: status.value,
        text: $this.msg(status.text)
      }))
    },
  },
  created() {
    let $this = this;
    this.setFields();
    this.refresh()
    this.$root.$on('crm::branch-updated', (this.listeners.refresh = () => {
      $this.refresh()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::branch-updated', this.listeners.refresh)
  },
  methods: {
    ...mapActions('gas', ['getAllGass', 'copySuccess']),
    ...mapActions('clients', ['getClients']),
    showRealEstateModal() {
      // Fetch the list of real estates when the modal is opened
      this.showModal = true;
    },

    routeToProperty(propertyId) {
      window.open(`/real_estate/edit/${propertyId}`, '_blank');
    },
    onCopy: function (e) {
      this.copySuccess(e.text)
    },
    onError: function (e) {
      alert('Failed to copy the text to the clipboard')
    },

    setFields() {
      let $this = this;

      this.fields = [

        {
          key: 'imageUri',
          label: $this.msg('imageUri'),
          sortable: false
        },
        {
          key: 'status',
          label: $this.msg('status'),
          sortable: true
        },
        {
          key: 'propertyIds',
          label: $this.msg('realEstate'),
          sortable: true
        },
        {
          key: 'provider',
          label: $this.msg('Provider'),
          sortable: true
        },
        {
          key: 'thermeType',
          label: $this.msg('Type'),
          sortable: true
        },
        {
          key: 'registered',
          label: $this.msg('Registered'),
          sortable: true
        },
        {
          key: 'kwUsage',
          label: $this.msg('KW Usage'),
          sortable: true
        },
        {
          key: 'brand',
          label: $this.msg('Brand'),
          sortable: true
        },
        {
          key: 'partialAmount',
          label: $this.msg('Partial Amount'),
          sortable: true
        },
        {
          key: 'userManual',
          label: $this.msg('User Manual'),
          sortable: false
        },
        {
          key: 'thermeLink',
          label: $this.msg('Therme Link'),
          sortable: false
        },
        {
          key: 'reset',
          label: $this.msg('Resetting'),
          sortable: false
        },
        {
          key: 'account',
          label: $this.msg('Account'),
          sortable: false
        },
        {
          key: 'password',
          label: $this.msg('Password'),
          sortable: false
        }
      ];

    },

    copyId(id) {
      console.log("the id copied was: ", id)
    },

    refresh() {
      let $this = this
      this.loading = true;
      this.sort = {
        by: 'creationDate',
        desc: true,
        direction: 'desc'
      },
          this.getAllGass({
            page: $this.page.currentPage,
            amount: $this.page.amount,
            filter: $this.filter,
            sortcolumn: $this.sort.by,
            sortby: $this.sort.direction,
          }).then(this.onGassRetrieve, (error) => $this.error = error)

    },
    onGassRetrieve(data) {
      this.page = {
        amount: data['Amount'],
        total: data['Total'],
        currentPage: data['Page']
      };
      this.sort = {
        by: data["sortColumn"],
        direction: data["sortOrder"]
      }
      this.gass = data["Gass"];
      this.realEstates = data["RealEstates"];
      this.loading = false;
    },

    onNewGas(id) {

      this.dialogs.newGas = true

    },
    onSort(data) {
      this.sort.by = data.sortBy;
      this.sort.direction = data.sortDesc ? "desc" : "asc";
      this.refresh();
    },
    onRowClick(gas) {
      this.selected = gas.id;
      this.dialogs.updateGas = true
    },

    onSubmitted(server) {
      if (server.id != null)
        this.selected = server.id;
      this.refresh()
      this.dialogs.newGas = false;
      this.dialogs.updateGas = false;
    },
    onClientSelect(clientId) {
      this.$root.$emit('bv::hide::popover')
      this.$root.$emit('crm::client::select', clientId)
    },
  }
}
</script>

<style>

</style>
